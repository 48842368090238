import logo from "../logo.svg";
import "../App.css";
import arrowMenu from "../assets/arrowMenu.png";
import logoKbn from "../assets/logoKbn.png";
import kbnLogoBot from "../assets/kbnLogoBot.png";
import glass from "../assets/glass.png";
import homeSmall from "../assets/homeSmall.png";
import landingImg from "../assets/landingImg.png";
import mengapa from "../assets/mengapa.png";
import propertiHome from "../assets/propertiHome.png";
import logistikHome from "../assets/logistikHome.png";
import plbHome from "../assets/plbHome.png";
import kbnBetonHome from "../assets/kbnBetonHome.png";
import rsuHome from "../assets/rsuHome.png";
import puHome from "../assets/puHome.png";
import fasumHome from "../assets/fasumHome.png";
import bisnisHome from "../assets/bisnisHome.png";
import landing2 from "../assets/landing2.jpg";
import landing3 from "../assets/landing3.jpg";
import locFooter from "../assets/locFooter.png";
import prop4 from "../assets/prop4.jpg";
import phoneFooter from "../assets/phoneFooter.png";
import faxFooter from "../assets/faxFooter.png";
import mailFooter from "../assets/mailFooter.png";
import leftArrow from "../assets/leftArrow.png";
import rightArrow from "../assets/rightArrow.png";
import moment from "moment";
import gbg from "../assets/gbg.png";
import ybg from "../assets/ybg.png";
import pleft from "../assets/pleft.png";
import pright from "../assets/pright.png";

import penghargaan1 from "../assets/penghargaan1.png";
import penghargaan2 from "../assets/penghargaan2.png";
import penghargaan3 from "../assets/penghargaan3.png";
import tenant4 from "../assets/tenant4.png";
import news from "../assets/news.png";
import mobileLanding from "../assets/mobileLanding.png";
import close from "../assets/close.png";
import React, { useEffect, useState } from "react";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-fade";
import Aos from "aos";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination, EffectFade } from "swiper/modules";
import { useProgressiveImage } from "../components/ImgLazyLoad.js";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { server } from "../config/server.js";
export function App() {
  const [width, setWidth] = useState(0);
  const [swiper, setSwiper] = useState(<Swiper />);
  const [swiperLanding, setSwiperLanding] = useState(<Swiper />);
  const [swiperLayanan, setSwiperLayanan] = useState(<Swiper />);
  const [swiperSertifikat, setSwiperSertifikat] = useState(<Swiper />);
  const [swiperStock, setSwiperStock] = useState(<Swiper />);
  const [swiperPenghargaan, setSwiperPenghargaan] = useState(<Swiper />);
  const [swiperTenant, setSwiperTenant] = useState(<Swiper />);
  const [newsImg, setNewsImg] = useState(news);
  const [loaded, setLoaded] = useState({});
  const [newsList, setNewsList] = useState([]);
  const [stock, setStock] = useState([]);
  const [slider, setSlider] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [penghargaanArr, setPenghargaanArr] = useState([]);
  const [sertifikatArr, setSertifikatArr] = useState([]);
  const [layananArr, setLayananArr] = useState([]);

  const [whyUsArr, setWhyUsArr] = useState([]);
  const MySwal = withReactContent(Swal);

  const defaultDataWhyUS = [
    {
      description:
        "Mendorong pertumbuhan perekonomian secara berkelanjutan melalui pengembangan ekosistem industri yang bernilai tambah",
    },
    {
      description:
        "Membuka peluang investasi dalam negeri dan asing melalui kontribusi pendapatan kepada negara berupa pajak dan non pajak",
    },
    {
      description:
        "Menjalankan tanggungjawab lingkungan dan sosial sehingga terciptanya dampak berkelanjutan bagi masyarakat dan daerah sekitar",
    },
    {
      description:
        "PT KBN menyediakan 3 (tiga) lokasi kawasan industri yang paling strategis di Jakarta, jantung Indonesia, untuk investasi, usaha manufaktur dan logistik yang sangat dekat dengan akses tol lingkar luar (JOR) untuk menuju pelabuhan laut maupun pelabuhan udara.",
    },
  ];

  // const penghargaanArr = [
  //   {
  //     name: "Nusantara CSR Awards 2021",
  //     text: "Nusantara CSR Awards 2021",
  //     src: penghargaan2,
  //   },
  //   {
  //     name: "(PROPER) Peringkat Biru Periode 2020-2021",
  //     text: "(PROPER) Peringkat Biru Periode 2020-2021",
  //     src: penghargaan2,
  //   },
  //   {
  //     name: "Nusantara CSR Awards 2021",
  //     text: "Penghargaan 11th Anugerah BUMN 2022",
  //     src: penghargaan3,
  //   },
  //   {
  //     name: "Nusantara CSR Awards 2021",
  //     text: "Nusantara CSR Awards 2021",
  //     src: penghargaan2,
  //   },
  //   {
  //     name: "Nusantara CSR Awards 2021",
  //     text: "(PROPER) Peringkat Biru Periode 2020-2021",
  //     src: penghargaan2,
  //   },
  //   {
  //     name: "Nusantara CSR Awards 2021",
  //     text: "Penghargaan 11th Anugerah BUMN 2022",
  //     src: penghargaan3,
  //   },
  //   {
  //     name: "Nusantara CSR Awards 2021",
  //     text: "Nusantara CSR Awards 2021",
  //     src: penghargaan2,
  //   },
  //   {
  //     name: "Nusantara CSR Awards 2021",
  //     text: "(PROPER) Peringkat Biru Periode 2020-2021",
  //     src: penghargaan3,
  //   },
  //   {
  //     name: "Nusantara CSR Awards 2021",
  //     text: "Nusantara CSR Awards 2021",
  //     src: penghargaan2,
  //   },
  // ];
  // const layananArr = [
  //   {
  //     name: "Properti",
  //     text: "",
  //     img: propertiHome,
  //     route: "/produk/properti",
  //   },
  //   {
  //     name: "Logistik",
  //     text: "",
  //     img: logistikHome,
  //     route: "/produk/logistik",
  //   },
  //   {
  //     name: "PLB",
  //     text: "",
  //     img: plbHome,
  //     route: "/produk/plb",
  //   },
  //   {
  //     name: "KBN Beton",
  //     text: "",
  //     img: kbnBetonHome,
  //     route: "/produk/kbn-beton",
  //   },
  //   {
  //     name: "RS Umum & Pekerja",
  //     text: "",
  //     img: rsuHome,
  //     route: "/produk/rsu",
  //   },
  //   {
  //     name: "Pusat Bisnis",
  //     text: "",
  //     img: bisnisHome,
  //     route: "/produk/pusat-bisnis",
  //   },
  //   {
  //     name: "SBU Pengelolaan Air",
  //     text: "",
  //     img: puHome,
  //     route: "/produk/sbu-pengelolaan-air",
  //   },
  //   {
  //     name: "Fasilitas Umum",
  //     text: "",
  //     img: fasumHome,
  //     route: "/produk/fasilitas-umum",
  //   },
  // ];

  const fetchAwardData = () => {
    fetch(`${server}/api/v1/home/award`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);

        if (res.status && res.data.length > 0) {
          const penghargaan = res.data.filter(
            (item) => item.is_certificate === 0
          );
          const sertifikat = res.data.filter(
            (item) => item.is_certificate === 1
          );

          setPenghargaanArr(penghargaan);
          setSertifikatArr(sertifikat);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchLayananData = () => {
    fetch(`${server}/api/v1/home/service`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLayananArr(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchWhyUS = () => {
    fetch(`${server}/api/v1/home/whyUs`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setWhyUsArr(res.data);
        } else {
          setWhyUsArr(defaultDataWhyUS);
        }
      })
      .catch((err) => {
        console.log(err);
        setWhyUsArr(defaultDataWhyUS);
      });
  };

  const navigate = useNavigate();
  useEffect(() => {
    eventModal();
    Aos.init({ duration: 2000 });
    console.log("start");
    getNews();
    getStock();
    getSlider();
    getTenant();
    fetchAwardData();
    fetchLayananData();
    fetchWhyUS();
  }, [slider]);

  const getStock = () => {
    fetch(`${server}/api/v1/cms/avaliable_space`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        setStock(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const getSlider = () => {
    fetch(`${server}/api/v1/home/banner`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.data.length > 0 && slider.length == 0) {
          console.log(res.data, "slider");

          setSlider(res.data);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const getTenant = () => {
    fetch(`${server}/api/v1/home/tenant`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())

      .then(async (res) => {
        if (res.data.length > 0 && tenants.length == 0) {
          setTenants(res.data);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const getNews = () => {
    fetch(`${server}/api/v1/news/list`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        setNewsList(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const eventModal = async (e) => {
    const img = server + "/storage/assets/popup/popup.png";
    checkImageAvailable(img);
    console.log(await checkImageAvailable(img));

    const eventModal = MySwal.mixin({
      // toast: true,
      allowOutsideClick: false,
      customClass: "home-popup",
      className: "home-popup",
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup:
          "animated fadeInDown faster home-popup center-vertical swalEvent",
        icon: "animated heartBeat delay-1s",
      },
      hideClass: {
        popup: "animated fadeOutUp faster",
      },
      showConfirmButton: false,
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger",
      },
      backdrop: true,
      allowOutsideClick: false,
      html: (
        <div>
          <img
            onClick={(e) => {
              MySwal.close();
            }}
            style={{
              height: 40,
              marginLeft: "auto",
              position: "absolute",
              cursor: "pointer",
              right: 20,
              top: 10,
              filter: "brightness(0) invert(1)",
            }}
            src={close}
          ></img>
          <img
            class="popupImg "
            src={`${server}/storage/assets/popup/popup.png`}
          />
          {/* <div style={{ textAlign: "center" }}>
             <button
              style={{
                borderRadius: 12,
                 paddingTop: "0.66rem",
                 paddingBottom: "0.66rem",
             }}
               class="text-center monsterratSemiBold blue-text-1 f085r cabangTabButton mx-4 px-4 my-2 "
               onClick={(x) => {
                 // e.preventDefault()
                navigate(`/announcement/npwp-menjadi-nik`);
                 MySwal.close();
               }}
             >
               Selengkapnya
             </button>
           </div> */}
        </div>
        // <div class=" px-3 py-3 text-center">
        //      <div class=" w100 " style={{ paddingLeft: 30 }}>
        //     <div class="none-desktop d1000" style={{ height: 40 }}></div>
        //     <div
        //       class="none-1000"
        //       style={{ float: "right", textAlign: "right" }}
        //     >
        //       <img
        //         onClick={(e) => {
        //           MySwal.close();
        //         }}
        //         style={{ height: 40, marginLeft: "auto", cursor: "pointer" }}
        //         src={close}
        //       ></img>
        //     </div>
        //   </div>{" "}
        //   <div class=" eventModalImgDiv  py-1 px-3">
        //     <div
        //       class="none-desktop d1000"
        //       style={{ float: "right", textAlign: "right" }}
        //     >
        //       <img
        //         onClick={(e) => {
        //           MySwal.close();
        //         }}
        //         style={{ height: 40, marginLeft: "auto" }}
        //         src={close}
        //       ></img>
        //     </div>
        //     <img class="loadingImgModal eventModalImg " src={nikNpwp} />
        //   </div>

        //   <div style={{ textAlign: "right" }}>
        //     <button
        //       style={{
        //         borderRadius: 12,
        //         paddingTop: "0.66rem",
        //         paddingBottom: "0.66rem",
        //       }}
        //       class="text-center monsterratSemiBold blue-text-1 f085r cabangTabButton mx-4 px-4 my-2 "
        //       onClick={(x) => {
        //         // e.preventDefault()
        //         navigate(`/cabang/${e.branch_id}`);
        //         MySwal.close();
        //       }}
        //     >
        //       Selengkapnya
        //     </button>
        //   </div>
        // </div>
      ),
      showConfirmButton: false,
      heightAuto: false,
    });
    if (await checkImageAvailable(img)) {
      return eventModal.fire();
    } else {
      return;
    }
  };
  const sertifikatStyle = () => {
    let a = document.getElementById("gbg").offsetHeight;
    console.log(document.getElementById("gbg").offsetHeight, "titi", a);
    document.getElementById("sertifikat").style.height = `${a}px`;

    // document.getElementById("ybg").style.height = `${a}px`;
    document.getElementById("ptitle").style.width = `200px`;
    document.getElementById("stitle").style.width = `200px`;
  };

  const NavbarDesktop = () => {
    return (
      <>
        <div class="navbarContainer upperHomeContainer mx-auto">
          <div
            class=""
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",

              display: "inline-flex",
            }}
          >
            <div
              class="center-vertical"
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
                gap: 6,
                display: "flex",
              }}
            >
              <div
                class="center-vertical"
                style={
                  {
                    // border: "1.50px #005F3B solid",
                  }
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="21"
                  viewBox="0 0 16 21"
                  fill="none"
                >
                  <path
                    d="M8.24947 5.49405L6.52016 1.45844C6.32065 0.992857 5.74128 0.810346 5.25696 1.06053L2.28386 2.59634C1.39902 3.05342 0.888611 3.98905 1.02066 4.91195C1.81597 10.4707 4.42781 15.5434 8.48832 19.4154C9.16251 20.0583 10.2196 20.1842 11.1043 19.7272L14.1057 18.1767C14.592 17.9256 14.7786 17.3437 14.5119 16.9109L12.208 13.1721C11.9669 12.7808 11.4395 12.6535 11 12.8806L9.54254 13.6335C9.38042 13.7172 9.18642 13.6876 9.07189 13.5618C7.59681 11.9415 6.58317 9.97295 6.12045 7.82972C6.08452 7.66329 6.17299 7.48781 6.33505 7.40409L7.78811 6.65348C8.22948 6.42549 8.43131 5.91836 8.24947 5.49405Z"
                    stroke="#005F3B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                style={{
                  color: "#005F3B",
                  fontSize: 18,
                  fontFamily: "Arial",
                  fontWeight: "400",

                  wordWrap: "break-word",
                }}
              >
                (021) 4482-0909
              </div>
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 56,
                display: "flex",
              }}
            >
              <div
                style={{
                  color: "#005F3B",
                  fontSize: 18,
                  fontFamily: "Roboto",
                  fontWeight: "300",

                  wordWrap: "break-word",
                }}
              >
                WBS
              </div>
              <div
                style={{
                  color: "#005F3B",
                  fontSize: 18,
                  fontFamily: "Roboto",
                  fontWeight: "300",

                  wordWrap: "break-word",
                }}
              >
                CRM
              </div>
              <div
                style={{
                  color: "#005F3B",
                  fontSize: 18,
                  fontFamily: "Roboto",
                  fontWeight: "300",

                  wordWrap: "break-word",
                }}
              >
                E PROC
              </div>
              <div
                style={{
                  color: "#005F3B",
                  fontSize: 18,
                  fontFamily: "Roboto",
                  fontWeight: "300",

                  wordWrap: "break-word",
                }}
              >
                PPID
              </div>
              <div
                style={{
                  color: "#005F3B",
                  fontSize: 18,
                  fontFamily: "Roboto",
                  fontWeight: "300",

                  wordWrap: "break-word",
                }}
              >
                Bahasa
              </div>
            </div>
          </div>
          <div class="navbarWrapper"></div>
        </div>
        <div
          class="upperHomeContainer mx-auto"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            style={{
              width: 105,
              height: 83,
            }}
            src={logoKbn}
          />
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              gap: 20,
              display: "flex",
            }}
          >
            <div
              class="center-vertical"
              style={{
                width: 20,
                height: 20,
              }}
            >
              <img src={homeSmall}></img>
            </div>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                gap: 20,
                display: "flex",
              }}
            >
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 5,
                  display: "flex",
                }}
                class="dropdownMenu pointer"
              >
                <div
                  class="f18 center-vertical"
                  style={{
                    color: "#005F3B",
                    fontSize: 18,
                    fontFamily: "Roboto",
                    fontWeight: "300",

                    wordWrap: "break-word",
                  }}
                >
                  Profil Perusahaan
                </div>

                <div class="dropdownDialog">
                  <div class="dropdownSubmenu pointer">Sejarah Singkat</div>
                  <div class="dropdownSubmenu pointer">Visi dan Misi </div>
                  <div class="dropdownSubmenu pointer">Bisnis Perusahaan</div>
                  <div class="dropdownSubmenu pointer">Tujuan dan Budaya</div>
                  <div class="dropdownSubmenu pointer">GCG</div>
                  <div class="dropdownSubmenu pointer">PKBL</div>
                  <div class="dropdownSubmenu pointer">Laporan</div>
                </div>
                <div
                  class="center-vertical"
                  style={{
                    width: 10,
                    height: 15,
                    cursor: "pointer",
                    // transform: "rotate(90deg)",
                    // transformOrigin: "0 0",
                    // border: "1px #005F3B solid",
                  }}
                >
                  <img src={arrowMenu}></img>
                </div>
              </div>
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 5,
                  display: "flex",
                }}
                class="dropdownMenu pointer"
              >
                <div
                  class="f18 center-vertical"
                  style={{
                    color: "#005F3B",
                    fontSize: 18,
                    fontFamily: "Roboto",
                    fontWeight: "300",

                    wordWrap: "break-word",
                  }}
                >
                  Produk Kami
                </div>
                <div class="dropdownDialog">
                  <div class="dropdownSubmenu pointer">Sejarah Singkat</div>
                  <div class="dropdownSubmenu pointer">Visi dan Misi </div>
                  <div class="dropdownSubmenu pointer">Bisnis Perusahaan</div>
                  <div class="dropdownSubmenu pointer">Tujuan dan Budaya</div>
                  <div class="dropdownSubmenu pointer">GCG</div>
                  <div class="dropdownSubmenu pointer">PKBL</div>
                  <div class="dropdownSubmenu pointer">Laporan</div>
                </div>
                <div
                  class="center-vertical"
                  style={{
                    width: 10,
                    height: 15,
                    cursor: "pointer",
                    // transform: "rotate(90deg)",
                    // transformOrigin: "0 0",
                    // border: "1px #005F3B solid",
                  }}
                >
                  <img src={arrowMenu}></img>
                </div>
              </div>
              <div
                class="dropdownMenu pointer"
                style={{
                  color: "#005F3B",
                  fontSize: 18,
                  fontFamily: "Roboto",
                  fontWeight: "300",

                  wordWrap: "break-word",
                }}
              >
                Katalog Produk
                <div class="dropdownDialog">
                  <div class="dropdownSubmenu pointer">Sejarah Singkat</div>
                  <div class="dropdownSubmenu pointer">Visi dan Misi </div>
                  <div class="dropdownSubmenu pointer">Bisnis Perusahaan</div>
                  <div class="dropdownSubmenu pointer">Tujuan dan Budaya</div>
                  <div class="dropdownSubmenu pointer">GCG</div>
                  <div class="dropdownSubmenu pointer">PKBL</div>
                  <div class="dropdownSubmenu pointer">Laporan</div>
                </div>
              </div>
              <div class="dropdownDialog">
                <div class="dropdownSubmenu pointer">Sejarah Singkat</div>
                <div class="dropdownSubmenu pointer">Visi dan Misi </div>
                <div class="dropdownSubmenu pointer">Bisnis Perusahaan</div>
                <div class="dropdownSubmenu pointer">Tujuan dan Budaya</div>
                <div class="dropdownSubmenu pointer">GCG</div>
                <div class="dropdownSubmenu pointer">PKBL</div>
                <div class="dropdownSubmenu pointer">Laporan</div>
              </div>
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 5,
                  display: "flex",
                }}
                class="dropdownMenu pointer"
              >
                <div
                  class="f18 center-vertical"
                  style={{
                    color: "#005F3B",
                    fontSize: 18,
                    fontFamily: "Roboto",
                    fontWeight: "300",

                    wordWrap: "break-word",
                  }}
                >
                  Suara Pelanggan
                </div>
                <div class="dropdownDialog">
                  <div class="dropdownSubmenu pointer">Sejarah Singkat</div>
                  <div class="dropdownSubmenu pointer">Visi dan Misi </div>
                  <div class="dropdownSubmenu pointer">Bisnis Perusahaan</div>
                  <div class="dropdownSubmenu pointer">Tujuan dan Budaya</div>
                  <div class="dropdownSubmenu pointer">GCG</div>
                  <div class="dropdownSubmenu pointer">PKBL</div>
                  <div class="dropdownSubmenu pointer">Laporan</div>
                </div>
                <div
                  class="center-vertical"
                  style={{
                    width: 10,
                    height: 15,
                    cursor: "pointer",
                    // transform: "rotate(90deg)",
                    // transformOrigin: "0 0",
                    // border: "1px #005F3B solid",
                  }}
                >
                  <img src={arrowMenu}></img>
                </div>
              </div>
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 5,
                  display: "flex",
                }}
                class="dropdownMenu pointer"
              >
                <div
                  class="f18 center-vertical"
                  style={{
                    color: "#005F3B",
                    fontSize: 18,
                    fontFamily: "Roboto",
                    fontWeight: "300",

                    wordWrap: "break-word",
                  }}
                >
                  Media
                </div>
                <div class="dropdownDialog">
                  <div class="dropdownSubmenu pointer">Sejarah Singkat</div>
                  <div class="dropdownSubmenu pointer">Visi dan Misi </div>
                  <div class="dropdownSubmenu pointer">Bisnis Perusahaan</div>
                  <div class="dropdownSubmenu pointer">Tujuan dan Budaya</div>
                  <div class="dropdownSubmenu pointer">GCG</div>
                  <div class="dropdownSubmenu pointer">PKBL</div>
                  <div class="dropdownSubmenu pointer">Laporan</div>
                </div>
                <div
                  class="center-vertical"
                  style={{
                    width: 10,
                    height: 15,
                    cursor: "pointer",
                    // transform: "rotate(90deg)",
                    // transformOrigin: "0 0",
                    // border: "1px #005F3B solid",
                  }}
                >
                  <img src={arrowMenu}></img>
                </div>
              </div>
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 5,
                  display: "flex",
                }}
                class="dropdownMenu pointer"
              >
                <div
                  class="f18 center-vertical"
                  style={{
                    color: "#005F3B",
                    fontSize: 18,
                    fontFamily: "Roboto",
                    fontWeight: "300",

                    wordWrap: "break-word",
                  }}
                >
                  Pengadaan Barang & Jasa
                </div>
                <div class="dropdownDialog">
                  <div class="dropdownSubmenu pointer">Sejarah Singkat</div>
                  <div class="dropdownSubmenu pointer">Visi dan Misi </div>
                  <div class="dropdownSubmenu pointer">Bisnis Perusahaan</div>
                  <div class="dropdownSubmenu pointer">Tujuan dan Budaya</div>
                  <div class="dropdownSubmenu pointer">GCG</div>
                  <div class="dropdownSubmenu pointer">PKBL</div>
                  <div class="dropdownSubmenu pointer">Laporan</div>
                </div>
                <div
                  class="center-vertical"
                  style={{
                    width: 10,
                    height: 15,
                    cursor: "pointer",
                    // transform: "rotate(90deg)",
                    // transformOrigin: "0 0",
                    // border: "1px #005F3B solid",
                  }}
                >
                  <img src={arrowMenu}></img>
                </div>
              </div>
              {/* <div
					style={{
						color: "#005F3B",
						fontSize: 18,
						fontFamily: "Roboto",
						fontWeight: "300",

						wordWrap: "break-word",
					}}
				>
					Pengadaan Barang & Jasa
				</div>
				<div
					style={{
						justifyContent: "flex-start",
						alignItems: "center",
						gap: 5,
						display: "flex",
					}}
					class="dropdownMenu pointer"
				>

<div class="dropdownDialog">
						<div class="dropdownSubmenu pointer">Sejarah Singkat</div>
						<div class="dropdownSubmenu pointer">Visi dan Misi </div>
						<div class="dropdownSubmenu pointer">Bisnis Perusahaan</div>
						<div class="dropdownSubmenu pointer">Tujuan dan Budaya</div>
						<div class="dropdownSubmenu pointer">GCG</div>
						<div class="dropdownSubmenu pointer">PKBL</div>
						<div class="dropdownSubmenu pointer">Laporan</div>
					</div>          <div
						class="center-vertical"
						style={{
							width: 10,
							height: 15,
							cursor: "pointer",
							// transform: "rotate(90deg)",
							// transformOrigin: "0 0",
							// border: "1px #005F3B solid",
						}}
					>
						<img src={arrowMenu}></img>
					</div>
				</div> */}
            </div>
            <div
              class="center-vertical"
              style={{
                width: 20,
                height: 20,
              }}
            >
              <img class="w100" src={glass}></img>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {/* NAVBAR VERSI DESKTOP */}
      {/* NAVBAR VERSI MOBILE */}
      {/* BAGIAN ATASD VERSI DESKTOP */}

      <>
        {/* LANDING */}
        <div
          id="landing"
          class="landingContainer  mx-auto flex "
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 10,
            position: "relative",
            borderRadius: 30,
          }}
        >
          <div class="w100  flex">
            <div class="w100 center-vertical text-center">
              <img
                class="center-vertical mx-auto pointer swiperNav"
                src={leftArrow}
                onClick={(e) => {
                  swiperLanding.slidePrev();
                }}
              />
            </div>
            <div class="upperHomeContainer mx-auto ">
              <div class="okgas"></div>
              {/* {slider.length} */}
              {slider.length > 0 ? (
                <Swiper
                  effect={"fade"}
                  grabCursor={true}
                  centeredSlides={false}
                  slidesPerView={1}
                  onInit={(ev) => {
                    setSwiperLanding(ev);
                  }}
                  // pagination={{ clickable: true, enabled: true, type: "bullets" }}
                  loop={true}
                  // coverflowEffect={{
                  //   rotate: 50,
                  //   stretch: 0,
                  //   depth: 100,
                  //   modifier: 1,
                  //   slideShadows: false,
                  // }}

                  breakpoints={{
                    1024: {
                      slidesPerView: 1,
                    },
                    // 768: {
                    //   slidesPerView: 1,
                    // },
                    // 200: {
                    //   slidesPerView: 1,
                    // },
                  }}
                  autoplay={{
                    delay: "1200",
                    // disableOnInteraction: true,
                    pauseOnMouseEnter: true,
                  }}
                  modules={[EffectFade, Autoplay, Pagination]}
                  className="swiperLanding"
                  // onTransitionEnd={(e) => ngobrolSlideChange(e)}
                  // navigation={true}
                  onSwiper={(swiper) => setSwiperLanding(swiper)}
                >
                  <div
                  // style={{
                  //   gap: 30,
                  // }}
                  >
                    {slider.length > 0 ? (
                      <>
                        {slider.map((slide, i) => {
                          return (
                            <SwiperSlide key={i}>
                              <Landing
                                source={slide.banner_public_link}
                                link={slide.banner_url || ""}
                              />
                            </SwiperSlide>
                          );
                        })}
                      </>
                    ) : null}
                  </div>
                </Swiper>
              ) : null}

              {/* <img class="w100" src={landingImg} /> */}
            </div>
            <div class="w100 center-vertical text-center">
              <img
                class="center-vertical mx-auto pointer swiperNav"
                src={rightArrow}
                onClick={(e) => {
                  swiperLanding.slideNext();
                }}
              />
            </div>
          </div>
        </div>
        <div class="w100  pt-5">
          <div
            class="homeText kbnAdalah m13 mx-auto mw80"
            style={{
              width: 1075,
              textAlign: "justify",
              fontSize: 24,
              overflowWrap: "break-word",
              fontFamily: "Roboto",
            }}
            // style="width: 1075px; text-align: justify; font-size: 24px; font-family: Roboto; overflow-wrap: break-word;"
          >
            <div
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="0"
              class="pb-5 w100 aos-init aos-animate"
              // style="text-align: center; font-weight: 400;"
              style={{ textAlign: "center", fontWeight: 400 }}
            >
              KBN merupakan perusahaan pengelola kawasan industri terpadu
              berstatus berikat yang berfungsi sebagai kawasan proses ekspor
              (export processing zone-EPZ) dan non-berikat, serta jasa pelayanan
              logistik yang meliputi usaha angkutan, mekanik &amp; dokumen
              (forwarding), dan pergudangan (warehousing)
            </div>
          </div>
        </div>
        {window.innerWidth > 1000 ? (
          <div
            data-aos="fade-up"
            data-aos-duration="750"
            class="upperHomeContainer flex mx-auto mt-4 pb-5"
            style={{
              backgroundColor: "",
            }}
          >
            <div
              class="w100 flex"
              style={{
                gap: 1,
              }}
            >
              {stock.map((stock, i) => {
                if (i == 0) {
                  return (
                    <>
                      <div class="w100 stockDetails" style={{}}>
                        <div
                          style={{
                            background: "#9CD9B4",
                            borderTopLeftRadius: 20,
                          }}
                        >
                          <div
                            class="greenText mx-3 pt-3 fw400 f24"
                            style={{
                              fontSize: 24,
                            }}
                          >
                            Available Space
                          </div>
                          <div
                            class="greenText mx-3 pt-1 pb-1 f36"
                            style={{
                              fontSize: 36,
                              fontFamily: "Roboto",
                              fontWeight: "600",
                            }}
                          >
                            KBN
                          </div>
                        </div>
                        <div
                          class=""
                          style={{
                            backgroundColor: "#EF582C",
                            borderBottomLeftRadius: 20,
                          }}
                        >
                          <div
                            class="fw400 py-2 text-right mx-3 f24"
                            style={{
                              color: "white",
                              fontSize: 24,
                            }}
                          >
                            Occupied
                          </div>
                        </div>
                      </div>

                      <div class="w100 stockDetails">
                        <div
                          style={{
                            background: "#9CD9B4",
                          }}
                        >
                          <div
                            class="greenText mx-3 pt-3 fw400 f24"
                            style={{
                              fontSize: 24,
                            }}
                          >
                            {stock.available_space_name}
                          </div>
                          <div
                            class="greenText mx-3 pt-1 pb-1 f36"
                            style={{
                              fontSize: 36,
                              fontFamily: "Roboto",
                              fontWeight: "600",
                            }}
                          >
                            {stock.available_space_avaliable}
                            {stock.available_space_unit}
                          </div>
                        </div>
                        <div
                          class=""
                          style={{
                            backgroundColor: "#EF582C",
                          }}
                        >
                          <div
                            class="fw400 py-2 text-right mx-3 f24"
                            style={{
                              color: "white",
                              fontSize: 24,
                            }}
                          >
                            {stock.available_space_occupied}
                            {stock.available_space_unit}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                } else if (i == 3) {
                  return (
                    <div class="w100 stockDetails">
                      <div
                        style={{
                          background: "#9CD9B4",
                          borderTopRightRadius: 20,
                        }}
                      >
                        <div
                          class="greenText mx-3 pt-3 fw400 f24"
                          style={{
                            fontSize: 24,
                          }}
                        >
                          {stock.available_space_name}
                        </div>
                        <div
                          class="greenText mx-3 pt-1 pb-1 f36"
                          style={{
                            fontSize: 36,
                            fontFamily: "Roboto",
                            fontWeight: "600",
                          }}
                        >
                          {stock.available_space_avaliable}
                          {stock.available_space_unit}
                        </div>
                      </div>
                      <div
                        class=""
                        style={{
                          backgroundColor: "#EF582C",
                          borderBottomRightRadius: 20,
                        }}
                      >
                        <div
                          class="fw400 py-2 text-right mx-3 f24"
                          style={{
                            color: "white",
                            fontSize: 24,
                          }}
                        >
                          {stock.available_space_occupied}
                          {stock.available_space_unit}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div class="w100 stockDetails">
                      <div
                        style={{
                          background: "#9CD9B4",
                        }}
                      >
                        <div
                          class="greenText mx-3 pt-3 fw400 f24"
                          style={{
                            fontSize: 24,
                          }}
                        >
                          {stock.available_space_name}
                        </div>
                        <div
                          class="greenText mx-3 pt-1 pb-1 f36"
                          style={{
                            fontSize: 36,
                            fontFamily: "Roboto",
                            fontWeight: "600",
                          }}
                        >
                          {stock.available_space_avaliable}
                          {stock.available_space_unit}
                        </div>
                      </div>
                      <div
                        class=""
                        style={{
                          backgroundColor: "#EF582C",
                        }}
                      >
                        <div
                          class="fw400 py-2 text-right mx-3 f24"
                          style={{
                            color: "white",
                            fontSize: 24,
                          }}
                        >
                          {stock.available_space_occupied}
                          {stock.available_space_unit}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ) : null}
        {window.innerWidth <= 1000 ? (
          <div
            class="upperHomeContainer flex mx-auto mt-4 pb-5"
            style={{
              backgroundColor: "",
            }}
          >
            <Swiper
              className="swiperStock"
              spaceBetween={1}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={false}
              slidesPerView={3}
              onInit={(ev) => {
                setSwiperStock(ev);
              }}
              loop={true}
              // coverflowEffect={{
              //   rotate: 50,
              //   stretch: 0,
              //   depth: 100,
              //   modifier: 1,
              //   slideShadows: false,
              // }}
              breakpoints={
                {
                  // 1024: {
                  //   slidesPerView: 3,
                  // },
                  // 768: {
                  //   slidesPerView: 1,
                  // },
                  // 200: {
                  //   slidesPerView: 1,
                  // },
                }
              }
              autoplay={{
                delay: "1500",
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              modules={[Autoplay]}
              pagination={true}
              // onTransitionEnd={(e) => ngobrolSlideChange(e)}
              // navigation={true}
              onSwiper={(swiper) => setSwiperStock(swiper)}
            >
              <div style={{}}>
                {stock.map((stock, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div
                        style={{
                          background: "#9CD9B4",
                        }}
                      >
                        <div
                          class="nowrap greenText mx-3 pt-3 fw400 m13"
                          style={{
                            fontSize: 13,
                          }}
                        >
                          {stock.available_space_name}
                        </div>
                        <div
                          class="nowrap greenText mx-3 pt-1 pb-1 f36 m20"
                          style={{
                            fontSize: 19.5,
                            fontFamily: "Roboto",
                            fontWeight: "600",
                          }}
                        >
                          {stock.available_space_avaliable}
                          {stock.available_space_unit}
                        </div>
                      </div>
                      <div
                        class="nowrap "
                        style={{
                          backgroundColor: "#EF582C",
                        }}
                      >
                        <div
                          class="fw400 py-2 text-right mx-3 f24 m13"
                          style={{
                            color: "white",
                            fontSize: 13,
                          }}
                        >
                          {stock.available_space_occupied}
                          {stock.available_space_unit}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </div>
            </Swiper>

            {/* <div
              class="w100 flex"
              style={{
                gap: 3,
              }}
            >
              <div class="w100" style={{}}>
                <div
                  style={{
                    background: "#9CD9B4",
                    borderTopLeftRadius: 20,
                  }}
                >
                  <div
                    class="greenText mx-3 pt-3 fw400 f24"
                    style={{
                      fontSize: 24,
                    }}
                  >
                    Available Space
                  </div>
                  <div
                    class="greenText mx-3 pt-1 pb-1 f36"
                    style={{
                      fontSize: 36,
                      fontFamily: "Roboto",
                      fontWeight: "600",
                    }}
                  >
                    200 Ha
                  </div>
                </div>
                <div
                  class=""
                  style={{
                    backgroundColor: "#EF582C",
                    borderBottomLeftRadius: 20,
                  }}
                >
                  <div
                    class="fw400 py-2 text-right mx-3 f24"
                    style={{
                      color: "white",
                      fontSize: 24,
                    }}
                  >
                    200 ha
                  </div>
                </div>
              </div>
              <div class="w100">
                <div
                  style={{
                    background: "#9CD9B4",
                  }}
                >
                  <div
                    class="greenText mx-3 pt-3 fw400 f24"
                    style={{
                      fontSize: 24,
                    }}
                  >
                    Available Space
                  </div>
                  <div
                    class="greenText mx-3 pt-1 pb-1 f36"
                    style={{
                      fontSize: 36,
                      fontFamily: "Roboto",
                      fontWeight: "600",
                    }}
                  >
                    200 Ha
                  </div>
                </div>
                <div
                  class=""
                  style={{
                    backgroundColor: "#EF582C",
                  }}
                >
                  <div
                    class="fw400 py-2 text-right mx-3 f24"
                    style={{
                      color: "white",
                      fontSize: 24,
                    }}
                  >
                    200 ha
                  </div>
                </div>
              </div>
              <div class="w100">
                <div
                  style={{
                    background: "#9CD9B4",
                  }}
                >
                  <div
                    class="greenText mx-3 pt-3 fw400 f24"
                    style={{
                      fontSize: 24,
                    }}
                  >
                    Available Space
                  </div>
                  <div
                    class="greenText mx-3 pt-1 pb-1 f36"
                    style={{
                      fontSize: 36,
                      fontFamily: "Roboto",
                      fontWeight: "600",
                    }}
                  >
                    200 Ha
                  </div>
                </div>
                <div
                  class=""
                  style={{
                    backgroundColor: "#EF582C",
                  }}
                >
                  <div
                    class="fw400 py-2 text-right mx-3 f24"
                    style={{
                      color: "white",
                      fontSize: 24,
                    }}
                  >
                    200 ha
                  </div>
                </div>
              </div>
              <div class="w100">
                <div
                  style={{
                    background: "#9CD9B4",
                  }}
                >
                  <div
                    class="greenText mx-3 pt-3 fw400 f24"
                    style={{
                      fontSize: 24,
                    }}
                  >
                    Available Space
                  </div>
                  <div
                    class="greenText mx-3 pt-1 pb-1 f36"
                    style={{
                      fontSize: 36,
                      fontFamily: "Roboto",
                      fontWeight: "600",
                    }}
                  >
                    200 Ha
                  </div>
                </div>
                <div
                  class=""
                  style={{
                    backgroundColor: "#EF582C",
                  }}
                >
                  <div
                    class="fw400 py-2 text-right mx-3 f24"
                    style={{
                      color: "white",
                      fontSize: 24,
                    }}
                  >
                    200 ha
                  </div>
                </div>
              </div>
              <div class="w100">
                <div
                  style={{
                    background: "#9CD9B4",
                    borderTopRightRadius: 20,
                  }}
                >
                  <div
                    class="greenText mx-3 pt-3 fw400 f24"
                    style={{
                      fontSize: 24,
                    }}
                  >
                    Available Space
                  </div>
                  <div
                    class="greenText mx-3 pt-1 pb-1 f36"
                    style={{
                      fontSize: 36,
                      fontFamily: "Roboto",
                      fontWeight: "600",
                    }}
                  >
                    200 Ha
                  </div>
                </div>
                <div
                  class=""
                  style={{
                    backgroundColor: "#EF582C",
                    borderBottomRightRadius: 20,
                  }}
                >
                  <div
                    class="fw400 py-2 text-right mx-3 f24"
                    style={{
                      color: "white",
                      fontSize: 24,
                    }}
                  >
                    200 ha
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        ) : null}
        {/* LAYANANAN */}
        <div
          class="my-5 py-5 text-center m27 homeSubheader f48"
          style={{
            color: "#005F3B",
            fontSize: 48,
            fontFamily: "Roboto",
            fontWeight: "800",
            textTransform: "uppercase",
            wordWrap: "break-word",
          }}
        >
          <a> Layanan Kami</a>
        </div>
        <div class="w100 flex ">
          <div
            class="w100  center-vertical text-center"
            style={{
              marginTop: -100,
            }}
          >
            <img
              class="center-vertical mx-auto pointer swiperNav"
              src={leftArrow}
              onClick={(e) => {
                swiperLayanan.slidePrev();
              }}
            />
          </div>
          <div class="upperHomeContainer mx-auto pb-5 ">
            <div
              class="flex w100"
              style={{
                gap: 200,
              }}
            >
              <Swiper
                autoplay={{
                  delay: "2000",
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                effect={"fade"}
                // effect={"coverflow"}
                grabCursor={true}
                centeredSlides={false}
                slidesPerView={5}
                spaceBetween={4}
                onInit={(ev) => {
                  setSwiperLayanan(ev);
                }}
                pagination={{
                  clickable: true,
                  enabled: true,
                  type: "bullets",
                  el: ".swiper-custom-pagination",
                  // dynamicBullets: true,
                }}
                loop={true}
                // coverflowEffect={{
                //   rotate: 50,
                //   stretch: 0,
                //   depth: 100,
                //   modifier: 1,
                //   slideShadows: false,
                // }}
                breakpoints={{
                  1000: {
                    slidesPerView: 5,
                  },
                  268: {
                    slidesPerView: 3,
                  },
                }}
                // autoplay={{
                //   delay: "5000",
                //   disableOnInteraction: false,
                //   pauseOnMouseEnter: true,
                // }}
                modules={[Autoplay, Pagination]}
                className="swiperLayanan"
                // onTransitionEnd={(e) => ngobrolSlideChange(e)}
                // navigation={true}
                onSwiper={(swiper) => setSwiperLayanan(swiper)}
              >
                <div
                  class="w100"
                  style={{
                    gap: "200px",
                  }}
                >
                  {layananArr.map((slide, i) => {
                    return (
                      <SwiperSlide
                        key={i}
                        onClick={(e) => {
                          navigate(slide.service_url);
                        }}
                      >
                        <div
                          class="w100 mb-5 layananImg"
                          style={{
                            borderRadius: 5,
                            backgroundImage: `url(${slide.service_public_link})`,
                            display: "inline-block",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            cursor: "pointer",
                          }}
                        >
                          {/* <img class="w100" src={slide.img} style={{
                          borderRadius:5}}/> */}
                        </div>
                        <div
                          class="text-center m13 layananMenu f24"
                          style={{
                            color: "#005F3B",
                            fontSize: 24,
                            fontFamily: "Roboto",
                            fontWeight: "800",
                            textTransform: "uppercase",

                            wordWrap: "break-word",
                          }}
                        >
                          <a> {slide.service_title}</a>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </div>
              </Swiper>
            </div>
          </div>
          <div
            class="w100 center-vertical text-center"
            style={{
              marginTop: -100,
            }}
          >
            <img
              class="center-vertical mx-auto pointer swiperNav"
              src={rightArrow}
              onClick={(e) => {
                swiperLayanan.slideNext();
              }}
            />
          </div>
        </div>
      </>
      <div className="swiper-custom-pagination w100 text-center mx-auto" />
      {/* {window.innerWidth < 768 ? (
        <>
      
          <div
            class=" upperHomeContainer mx-auto"
            style={{
              position: "relative",
            }}
          >
          
            <img class="w100" src={mobileLanding}></img>
          </div>
          <div
            style={{
              width: "100%",
              height: "140px",
              background: "#B2D234",
            }}
          />
        </>
      ) : null} */}

      <div
        class="mt-5 pt-5 pb-5 w100 mx-auto"
        style={
          {
            // display: "inline-flex",
          }
        }
      >
        <div
          style={{
            textAlign: "center",
            color: "#005F3B",
            fontSize: 48,
            fontFamily: "Roboto",
            fontWeight: "800",
            textTransform: "uppercase",

            wordWrap: "break-word",
          }}
          class="m27 pb-5 homeSubheader f48"
        >
          <a> MENGAPA MEMILIH KBN</a>
        </div>
        <div class="mengapaWrapper w100 flex">
          <div class=" mengapaPoinWrapper py-5 px-5">
            <div
              class="homeText m13 mx-auto f24"
              style={{
                // width: 1075,
                // textAlign: "justify",

                fontSize: 24,
                fontFamily: "Roboto",
                fontWeight: "300",
                wordWrap: "break-word",
              }}
            >
              {/* text why us */}
              {/* <ol
                style={{
                  gap: "1rem",
                  marginBottom: 0,
                }}
              >
                <div
                  class="py-3 px-5 mengapaPoin pageText mb-3"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                  style={{ zIndex: 99 }}
                >
                  <a>
                    <li>
                      Mendorong pertumbuhan perekonomian secara berkelanjutan
                      melalui pengembangan ekosistem industri yang bernilai
                      tambah
                    </li>
                  </a>
                </div>
                <div
                  class="py-3 px-5 mengapaPoin pageText mb-3"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <a>
                    <li>
                      Membuka peluang investasi dalam negeri dan asing melalui
                      kontribusi pendapatan kepada negara berupa pajak dan non
                      pajak
                    </li>
                  </a>
                </div>
                <div
                  class="py-3 px-5 mengapaPoin pageText mb-3"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <a>
                    <li>
                      Menjalankan tanggungjawab lingkungan dan sosial sehingga
                      terciptanya dampak berkelanjutan bagi masyarakat dan
                      daerah sekitar
                    </li>
                  </a>
                </div>
                <div
                  class="py-3 px-5 mengapaPoin pageText mb-3"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <a>
                    <li>
                      PT KBN menyediakan 3 (tiga) lokasi kawasan industri yang
                      paling strategis di Jakarta, jantung Indonesia, untuk
                      investasi, usaha manufaktur dan logistik yang sangat dekat
                      dengan akses tol lingkar luar (JOR) untuk menuju pelabuhan
                      laut maupun pelabuhan udara.
                    </li>
                  </a>
                </div>
              </ol> */}

              <ol
                style={{
                  gap: "1rem",
                  marginBottom: 0,
                }}
              >
                {whyUsArr.map((item, index) => (
                  <div
                    key={index}
                    className="py-3 px-5 mengapaPoin pageText mb-3"
                    data-aos="fade-right"
                    data-aos-duration="2000"
                    style={{ zIndex: 99 }}
                  >
                    <a>
                      <li>{item.description}</li>
                    </a>
                  </div>
                ))}
              </ol>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="2000"
            class="mengapaImgWrapper w100 "
            style={{
              backgroundImage: `url(${mengapa})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "auto 100%",
            }}
          ></div>
        </div>
      </div>

      <div className="w100 py-5 my-5 mx-auto">
        <div class="w100 flex none1000">
          <div class="w50">
            <div
              class="text-center pb-5 mb-5 m27 homeSubheader f48"
              style={{
                color: "#005F3B",
                fontSize: 48,
                fontFamily: "Roboto",
                fontWeight: "800",
                textTransform: "uppercase",
                wordWrap: "break-word",
              }}
            >
              <a> penghargaan</a>
            </div>
          </div>
          <div class="w50">
            <div
              class="text-center pb-5 mb-5 m27 homeSubheader f48"
              style={{
                color: "#005f3b",
                fontSize: 48,
                fontFamily: "Roboto",
                fontWeight: "800",
                textTransform: "uppercase",
                wordWrap: "break-word",
              }}
            >
              <a> Sertifikat</a>
            </div>
          </div>
        </div>
        <div class="w100 flex">
          <div
            class="text-center pb-5 mb-5 m27 homeSubheader w100 block1000 f48"
            style={{
              display: "none",
              color: "#005F3B",
              fontSize: 48,
              fontFamily: "Roboto",
              fontWeight: "800",
              textTransform: "uppercase",
              wordWrap: "break-word",
            }}
          >
            <a> penghargaan & sertifikat</a>
          </div>
        </div>
        <div class="w100 flex sertipeng">
          <div
            id="sertifikat"
            class="w50 flex w1000100"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            {/* <div class="w100 center-vertical text-center">
              <img
                id="gbg"
                class="imbg"
                style={{
                  width: "48vw",
                  position: "absolute",
                  opacity: "65%"
                }}
                onLoad={(e) => {
                  sertifikatStyle();
                }}
                src={gbg}
              ></img>
              <img
                class="center-vertical mx-auto pointer swiperNav"
                src={leftArrow}
                style={{
                  filter: "brightness(0) invert(1)",
                }}
                onClick={(e) => {
                  swiperPenghargaan.slidePrev();
                }}
              />
            </div> */}
            <div
              class="penghargaanWrapper flex center-vertical px-3"
              style={{
                backgroundColor: "#005f3b",
                clipPath: "polygon(0 0, 90% 0, 100% 100%, 0 100%)",
              }}
            >
              <img
                class="center-vertical mx-auto pointer swiperNav"
                src={leftArrow}
                style={{
                  filter: "brightness(0) invert(1)",
                }}
                onClick={(e) => {
                  swiperPenghargaan.slidePrev();
                }}
              />
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                // centeredSlides={false}
                slidesPerView={1}
                onInit={(ev) => {
                  setSwiperPenghargaan(ev);
                }}
                className="center-vertical "
                loop={true}
                // coverflowEffect={{
                //   rotate: 50,
                //   stretch: 0,
                //   depth: 100,
                //   modifier: 1,
                //   slideShadows: false,
                // }}

                breakpoints={{}}
                // autoplay={{
                //   delay: "2000",
                //   disableOnInteraction: false,
                //   pauseOnMouseEnter: true,
                // }}
                // modules={[Autoplay]}
                // pagination={true}
                // onTransitionEnd={(e) => ngobrolSlideChange(e)}
                // navigation={true}
                onSwiper={(swiper) => setSwiperPenghargaan(swiper)}
              >
                <div class="w100 flex">
                  {penghargaanArr.map((slide, i) => {
                    return (
                      <div>
                        <SwiperSlide key={i}>
                          <div class=" text-center" style={{}}>
                            <div class="center-vertical text-center mx-auto mb-3">
                              <img
                                class="awardImg mx-auto"
                                src={slide.award_public_link}
                                style={{ maxHeight: 300 }}
                              ></img>
                            </div>

                            {/* <img
                        src={star}
                        class="pb-4"
                        style={{
                          width: "25.5%",
                        }}
                      /> */}
                            <div class="flex justify-content-center">
                              <div class="flex mx-auto center-vertical">
                                <div>
                                  <img class="borderP" src={pleft}></img>
                                </div>
                                <div
                                  id="ptitle"
                                  class="px-3 m12 ptitle f20"
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: 20,
                                    fontFamily: "Roboto",

                                    textTransform: "capitalize",
                                  }}
                                >
                                  <span
                                    class="mb-3"
                                    style={{ fontWeight: "500" }}
                                  >
                                    <a> {slide.award_title}</a>
                                  </span>
                                  <br></br>
                                  <span stye={{ fontWeight: "400" }}></span>
                                </div>
                                <div>
                                  <img class="borderP" src={pright}></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </div>
                    );
                  })}
                </div>
              </Swiper>
            </div>

            <div class="w100 center-vertical text-center">
              <img
                class="center-vertical mx-auto pointer swiperNav"
                src={rightArrow}
                style={{
                  // filter: "brightness(0) invert(1)",
                  zIndex: 99,
                }}
                onClick={(e) => {
                  swiperPenghargaan.slideNext();
                }}
              />
            </div>
          </div>
          <div
            class="w50 flex w1000100 mt31000"
            style={
              {
                // marginTop: "3rem",
              }
            }
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <div class="w100 center-vertical text-center">
              {/* <img
                id=""
                class="imbg"
                style={{
                  width: "48vw",
                  position: "absolute",
                  right: 0,
                  opacity: "65%",
                }}
                src={ybg}
              ></img> */}
              <img
                class="center-vertical mx-auto pointer swiperNav"
                src={leftArrow}
                style={{
                  // filter: "brightness(0) invert(1)",
                  zIndex: 101,
                }}
                onClick={(e) => {
                  swiperSertifikat.slidePrev();
                }}
              />
            </div>
            <div
              class="penghargaanWrapper flex center-vertical px-3"
              style={{
                backgroundColor: "#f1dd7d",
                clipPath: "polygon(5% 0, 100% 0, 100% 100%, 0 100%)",
              }}
            >
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                // centeredSlides={false}
                slidesPerView={1}
                onInit={(ev) => {
                  setSwiperSertifikat(ev);
                }}
                loop={true}
                // coverflowEffect={{
                //   rotate: 50,
                //   stretch: 0,
                //   depth: 100,
                //   modifier: 1,
                //   slideShadows: false,
                // }}
                breakpoints={
                  {
                    // 1024: {
                    //   slidesPerView: 1,
                    // },
                    // 768: {
                    //   slidesPerView: 1,
                    // },
                    // 200: {
                    //   slidesPerView: 1,
                    // },
                  }
                }
                autoplay={{
                  delay: "21000",
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                modules={[Autoplay]}
                // pagination={true}
                // onTransitionEnd={(e) => ngobrolSlideChange(e)}
                // navigation={true}
                onSwiper={(swiper) => setSwiperSertifikat(swiper)}
              >
                <div class="w100 flex">
                  {sertifikatArr.map((slide, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <div class=" text-center">
                          <div class="center-vertical text-center mx-auto mb-3">
                            <img
                              class="awardImg mx-auto"
                              src={slide.award_public_link}
                              style={{ maxHeight: 300 }}
                            ></img>
                          </div>

                          {/* <img
                          src={star}
                          class="pb-4"
                          style={{
                            width: "25.5%",
                          }}
                        /> */}
                          <div class="flex justify-content-center">
                            <div class="flex mx-auto center-vertical">
                              <div>
                                <img class="borderP" src={pleft}></img>
                              </div>
                              <div
                                id="stitle"
                                class="px-3 m12 ptitle f20"
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: 20,
                                  fontFamily: "Roboto",

                                  textTransform: "capitalize",
                                }}
                              >
                                <span
                                  class="mb-3"
                                  style={{ fontWeight: "500" }}
                                >
                                  <a> {slide.award_title}</a>
                                </span>
                                <br></br>
                                <span stye={{ fontWeight: "400" }}></span>
                              </div>
                              <div>
                                <img class="borderP" src={pright}></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </div>
              </Swiper>
              <img
                class="center-vertical mx-auto pointer swiperNav"
                src={rightArrow}
                style={{
                  filter: "brightness(0) invert(1)",
                }}
                onClick={(e) => {
                  swiperSertifikat.slideNext();
                }}
              />
            </div>

            {/* <div class="w100 center-vertical text-center">
              <img
                class="center-vertical mx-auto pointer swiperNav"
                src={rightArrow}
                style={{
                  filter: "brightness(0) invert(1)",
                }}
                onClick={(e) => {
                  swiperSertifikat.slideNext();
                }}
              />
            </div> */}
          </div>
        </div>
      </div>

      {/* BERITA SECTION */}
      {/* BERITA DESKTOP */}
      <div
        style={{
          marginTop: "5rem",
          textAlign: "center",
          color: "#005F3B",
          fontSize: 48,
          fontFamily: "Roboto",
          fontWeight: "800",
          textTransform: "uppercase",

          wordWrap: "break-word",
        }}
        class="m27 mobile homeSubheader f48"
      >
        <a> Berita terbaru</a>
      </div>
      <div class="beritaContainer w100 flex flex-row-reverse my-5 py-5">
        <div
          id="newsImg"
          style={{
            position: "absolute",
            width: "45%",
            left: 0,
            height: 450,
            borderRadius: 5,
            backgroundImage: `url(${newsImg})`,
            display: "inline-block",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            cursor: "pointer",
          }}
          class="newsImgContainer"
        >
          {/* <img src={newsImg} class="w100 h100"></img> */}
        </div>
        <div
          class="berita"
          style={{
            // height: 600,
            width: "76%",
          }}
        >
          <div
            class="m-o0"
            style={{
              height: "20%",
            }}
          >
            <div
              class="text-center mx-auto mx-auto pb-5 f48"
              style={{
                textAlign: "center",
                color: "#005F3B",
                fontSize: 48,
                fontFamily: "Roboto",
                fontWeight: "800",
                textTransform: "uppercase",

                wordWrap: "break-word",
              }}
            >
              <span class="beritaTitle homeSubheader">
                <a>BERITA TERBARU</a>
              </span>
            </div>
          </div>
          <div
            class="beritaList flex justify-content-between"
            style={{
              height: "80%",
              backgroundColor: "#166636",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "0px",
                height: "0px",
              }}
            ></div>
            <div
              class="beritaListWrapper"
              style={{
                width: "67%",
                height: "90%",
                marginLeft: "30%",
                backgroundColor: "#166636",
                // marginTop: "auto",
              }}
            >
              <div
                style={{
                  // backgroundColor: "red",
                  gap: 60,
                  height: "92%",
                  overflow: "auto",
                }}
                class="beritaWrapper"
              >
                {newsList.map((news, i) => {
                  return (
                    <div
                      onClick={async (e) => {
                        navigate(`/media/berita/${news.news_id}`, {
                          state: { news: news },
                        });
                        // navigate(`/media/berita/${news.id}`);
                      }}
                      onMouseOver={(e) => {
                        setNewsImg(news.images[0].public_link);
                      }}
                      style={{
                        borderLeft: "3px solid white",
                        // height: "200px",
                        // backgroundColor: "blue"
                      }}
                      class="w100 mb-5 px-4"
                    >
                      <div>
                        <div
                          style={{
                            color: "white",
                            fontSize: 22,
                            fontFamily: "Roboto",
                            fontWeight: "800",
                            textTransform: "uppercase",
                            wordWrap: "break-word",
                            marginTop: "",
                          }}
                          class="newsTitle m12 f22"
                        >
                          <a> {news.news_title}</a>
                        </div>
                        <div
                          class="py-2 m11 f20"
                          style={{
                            color: "white",
                            fontSize: 20,
                            fontFamily: "Roboto",
                            fontWeight: "500",

                            wordWrap: "break-word",
                          }}
                        >
                          <a>
                            {moment(news.news_entry_date)
                              .locale("id")
                              .format("DD MMM YYYY")}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                class="center-vertical"
                style={{
                  textAlign: "left",
                  color: "white",
                  fontSize: 13,
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  textTransform: "uppercase",
                  height: "8%",
                  wordWrap: "break-word",
                  cursor: "pointer",
                  // marginTop: 9,
                  // marginBottom: 9,
                }}
                onClick={async (e) => {
                  navigate(`/media/berita`);
                  // navigate(`/media/berita/${news.id}`);
                }}
              >
                <a> lihat selengkapnya</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MOBILe VERSION */}

      {/* TENANT SECTION */}

      <div class="w100 mw800 flex mx-auto">
        <div class="w100  py-4 mx-auto">
          <div
            class="text-center mx-auto mx-auto pb-5 m27 homeSubheader f48"
            style={{
              textAlign: "center",
              color: "#005F3B",
              fontSize: 48,
              fontFamily: "Roboto",
              fontWeight: "800",
              textTransform: "uppercase",

              wordWrap: "break-word",
            }}
          >
            TENANT KAMI
          </div>
          <div class="flex justify-content-center">
            <div class=" center-vertical text-center">
              <img
                style={{ marginLeft: "2rem" }}
                class="center-vertical  pointer swiperNav"
                src={leftArrow}
                onClick={(e) => {
                  swiperTenant.slidePrev();
                }}
              />
            </div>
            <div class="mw600 flex tenantContainer" style={{ minWidth: 400 }}>
              <div class="w100 mx-auto">
                <div class="flex w100">
                  <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={false}
                    slidesPerView={3}
                    onInit={(ev) => {
                      setSwiperTenant(ev);
                    }}
                    loop={true}
                    // coverflowEffect={{
                    //   rotate: 50,
                    //   stretch: 0,
                    //   depth: 100,
                    //   modifier: 1,
                    //   slideShadows: false,
                    // }}
                    breakpoints={{
                      1000: {
                        slidesPerView: 3,
                      },
                      200: {
                        slidesPerView: 3,
                      },
                    }}
                    autoplay={{
                      delay: "1500",
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    modules={[Autoplay]}
                    pagination={true}
                    className="swiperTenant w100"
                    // onTransitionEnd={(e) => ngobrolSlideChange(e)}
                    // navigation={true}
                    onSwiper={(swiper) => setSwiperTenant(swiper)}
                  >
                    <div class="w100">
                      {tenants.length > 0 ? (
                        <>
                          {tenants.map((slide, i) => {
                            return (
                              <SwiperSlide key={i}>
                                {/* {JSON.stringify(slide)} */}
                                <div
                                  class="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    slide.tenant_url
                                      ? window.open(
                                          slide.tenant_url || "",
                                          "_blank",
                                          "noopener,noreferrer"
                                        )
                                      : console.log("no link");
                                  }}
                                >
                                  <img
                                    style={{ height: 70 }}
                                    class=""
                                    src={slide.tenant_public_link}
                                  ></img>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
            <div class=" center-vertical text-center">
              <img
                style={{ marginRight: "2rem" }}
                class="center-vertical  pointer swiperNav"
                src={rightArrow}
                onClick={(e) => {
                  swiperTenant.slideNext();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Landing({ source, link }) {
  const loaded = useProgressiveImage(source);
  return (
    <div
      class={`w100 text-center center-vertical swiper-landing-slide ${
        loaded ? "o1" : "o0"
      }`}
      onClick={(e) => {
        if (link.length > 0) {
          window.open(link, "_blank");
        }
      }}
      style={{
        height: "50rem",
        borderRadius: 20,
        backgroundImage: `url(${source})`,
        display: "inline-block",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        cursor: "pointer",
      }}
    >
      {/* {window.innerWidth > 1000 ? (
      <img class="w100" src={slide.img} />
    ) : (
      <img class="w100" src={slide.mobileImg} />
    )} */}
    </div>
  );
}
const checkImageAvailable = (src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};

export default App;
