import React, { useEffect, useState } from "react";
import { server, server_detail_eproc } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";
import logistikMain from "../../assets/logistikMain.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment-timezone";
import "moment/locale/id"; // Import bahasa Indonesia
export default function InformasiPemegangSaham({menu}) {
  const [data, setData] = useState([]);

  const [dataPengadaan, setDataPengadaan] = useState([]);
  const [dataPengadaanCurrent, setDataPengadaanCurrent] = useState([]);
  const itemsPerPage = 10;
  const [page, setPage] = useState(null);
  const params = useLocation();
  const currentPath = params.pathname;
  const navigate = useNavigate();


  useEffect(() => {

    // console.log(currentPath,'xy');
    if(!page){
      setPage(currentPath)
    }
    getData();
    getDataPengadaan();
    const allTab = document.getElementsByClassName("tabMenu");
    for (let i = 0; i < allTab.length; i++) {
      const element = allTab[i];
      if (element.id == page) {
        element.classList.add("tabActive");
      } else {
        element.classList.remove("tabActive");
      }
    }

    window.scrollTo(0, 0);
  }, [page]);
  // const handlePageClick = (event) => {
  //   window.scrollTo(0, 0);
  //   const newOffset = (event.selected * itemsPerPage) % data.length;
  //   console.log(
  //     `User requested page number ${event.selected}, which is offset ${newOffset}`
  //   );
  //   setItemOffset(newOffset);
  //   setDataCurrent(data.slice(newOffset, newOffset + itemsPerPage));
  // };
  const getData = async () => {
    try {
      const response = await fetch(`${server}/api/v1/eproc/list-pengumuman`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log(result, "dada");
      setData(result.result);
    } catch (err) {
      console.log(err);
    }
  };
  const getDataPengadaan = async () => {
    try {
      const response = await fetch(
        `${server}/api/v1/eproc/list-pengadaan/public`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      console.log(result, "ada");
      setDataPengadaan(result.result);
    } catch (err) {
      console.log(err);
    }
  };
  // const backgroundImage = data.length > 0 ? data[0].content_header_link.replace(/ /g, "%20") : null;
  // const contentHTML =
  //   data.length > 0 && data[0].content_html
  //     ? data[0].content_html
  //     : "<p>No Content Available";

  const Pengumuman = () => {
    const pageCount = Math.ceil(data.length / itemsPerPage);
    const [dataCurrent, setDataCurrent] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const handlePageClick = (event) => {
      window.scrollTo(0, 0);
      const newOffset = (event.selected * itemsPerPage) % data.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
      setDataCurrent(data.slice(newOffset, newOffset + itemsPerPage));
    };
    useEffect(() => {
      setDataCurrent(data.slice(0, 0 + itemsPerPage));

      window.scrollTo(0, 0);
    }, []);
    return (
      <>
        <div class="mt-4">
          <table class="table table-bordered mb-5">
            <thead class="thead-light">
              <tr>
                <th
                  style={{
                    width: "100px",
                  }}
                  scope="col"
                  class="text-center"
                >
                  No
                </th>
                <th scope="col" class="px-4">
                  Nama
                </th>
                <th
                  style={{
                    width: "300px",
                  }}
                  class="text-center"
                  scope="col"
                >
                  Nilai kontrak
                </th>
                <th
                  style={
                    {
                      // width: "200px",
                    }
                  }
                  scope="col"
                  class="text-center"
                >
             Vendor Pemenang
                </th>
                {/* <th scope="col"></th> */}
              </tr>
            </thead>
            <tbody>
              {dataCurrent.map((d, i) => {
                return (
                  <tr>
                    <td
                      class="text-center"
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      {itemOffset + i + 1}
                    </td>

                    <td
                      class="px-4"
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      {" "}
                      {d.kegiatan}
                    </td>

                    <td
                      class="px-4"
                      style={{
                        verticalAlign: "middle",
                        textAlign:'right'
                      }}
                    >
              {d.nilai_pekerjaan}
                   
                    </td>
                    <td
                      class="px-4"
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      {" "}
                      {d.penyedia}
                    </td>
                    {/* <td
                      class="px-4 text-center"
                      onClick={(e) => {
                        window.open(
                          server_detail_eproc +
                            "/paket-detail.html?id=" +
                            d.kegiatan_id
                        );
                      }}
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      {" "}
                      <a
                        style={{
                          color: "blue",
                          cursor: "pointer ",
                          textDecoration: "underline",
                          fontSize: "1rem",
                        }}
                        target="_blank"
                        onClick={(e) => {
                          window.open(
                            server_detail_eproc +
                              "/paket-detail.html?id=" +
                              d.kegiatan_id
                          );
                        }}
                      >
                        Lihat
                      </a>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* {dataCurrent.map((d, i) => {
            return (
              <div
                class="col-12 mb-4"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  window.open(server_detail_eproc + "/pengumuman.html");
                }}
              >
                <div class="product mb-0 d-flex flex-row align-items-center border-bottom pb-3">
                  <div class="date-container text-center flex-shrink-0 mx-5">
                    <div class="date-month">{itemOffset + i + 1}</div>
                  </div>{" "}
                  <div class="product-details ml-4 flex-grow-1 mx-5">
                    <a
                      href="javascript:void(0)"
                      class="badge badge-quaternary text-white"
                    >
                      {d.nilai_pekerjaan}
                    </a>{" "}
                    <h3 class="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                      <a
                        href="javascript:void(0)"
                        class="text-color-dark text-color-hover-primary"
                      >
                        {d.kegiatan}
                      </a>
                    </h3>{" "}
                    <p class="price text-3-5 mb-0">
                      <span class="badge badge-secondary text-white">
                        {d.penyedia}
                      </span>
                    </p>{" "}
                    <a
                      href="javascript:void(0)"
                      class="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
                    >
                      {d.penyedia_npwp}
                    </a>
                  </div>
                </div>
              </div>
              // <div class="flex">

              //   <div class="py-2">{itemOffset + i + 1}.</div>
              //   <div class="w100">

              //     <a
              //       href="https://eproc.kbn.co.id/"
              //       target="_blank"
              //       class="hoverNoColor"
              //     >
              //       <div
              //         class="mb-2 px-4 py-2 hoverDark "
              //         style={{
              //           backgroundColor: "#f5f5f5",
              //           borderRadius: 12,
              //           transition: "0.2s",
              //           cursor: "pointer",
              //         }}
              //       >
              //         <div class="flex justify-content-between">
              //           <div> {d.kegiatan}</div>
              //           <div class="flex">
              //             <a
              //               href="https://eproc.kbn.co.id/"
              //               style={{
              //                 color: "blue",
              //                 cursor: "pointer ",
              //                 textDecoration: "underline",
              //                 fontSize: "1rem",
              //               }}
              //               target="_blank"
              //             >
              //               selengkapnya
              //             </a>
              //           </div>
              //         </div>
              //         <div style={{ display: "flex" }}>
              //           <div
              //             style={{
              //               border: "1px solid #005f3b",
              //               borderRadius: 5,
              //               fontSize: "1rem",
              //             }}
              //             class="px-3 py-1"
              //           >
              //             {d.penyedia}
              //           </div>
              //           <div
              //             class="px-3 py-1"
              //             style={{
              //               border: "1px solid #005f3b",
              //               borderRadius: 5,
              //               fontSize: "1rem",
              //             }}
              //           >
              //             {d.nilai_pekerjaan}
              //           </div>
              //         </div>
              //         <div>

              //         </div>
              //       </div>
              //     </a>
              //   </div>
              // </div>
            );
          })} */}
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          className="pagination-class px-3"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          activeClassName="page-active"
        />
      </>
    );
  };
  const Pengadaan = () => {
    const pageCount = Math.ceil(dataPengadaan.length / itemsPerPage);
    const [dataCurrent, setDataCurrent] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const handlePageClick = (event) => {
      window.scrollTo(0, 0);
      const newOffset = (event.selected * itemsPerPage) % dataPengadaan.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
      setDataCurrent(dataPengadaan.slice(newOffset, newOffset + itemsPerPage));
    };
    useEffect(() => {
      setDataCurrent(dataPengadaan.slice(0, 0 + itemsPerPage));

      window.scrollTo(0, 0);
    }, []);
    return (
      <>
        <div class="mt-4">
          {/* {dataCurrent.map((d, i) => {
            return (
              <div
                class="col-12 mb-4"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  window.open(
                    server_detail_eproc +
                      "/paket-detail.html?id=" +
                      d.kegiatan_id
                  );
                }}
              >
                <div class="product mb-0 d-flex flex-row align-items-center border-bottom pb-3">
                  <div class="date-container text-center flex-shrink-0 mx-4 -m 'gas">
                    <div class="date-number">
                      {moment(d.kegiatan_tgl_mulai).format("DD")}
                    </div>{" "}
                    <div class="date-month">
                      {moment(d.kegiatan_tgl_mulai).format("MM.YY")}
                    </div>{" "}
                    <div class="date-month">{d.time}</div>
                  </div>{" "}
                  <div class="product-details ml-4 flex-grow-1 mx-4">
                    <a
                      href="paket-detail.html?id=92"
                      class="badge badge-quaternary text-white"
                    >
                      {d.kegiatan_metode}
                    </a>{" "}
                    <h3 class="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                      <a
                        href="paket-detail.html?id=92"
                        class="text-color-dark text-color-hover-primary"
                      >
                        {d.kegiatan}
                      </a>
                    </h3>{" "}
                    <p class="price text-3-5 mb-0">
                      <span class="sale text-color-dark font-weight-medium">
                        {d.kegiatan_lokasi}
                      </span>
                    </p>{" "}
                    <a
                      href="paket-detail.html?id=92"
                      class="badge badge-secondary text-white"
                    ></a>
                  </div>{" "}
                  <div class="date-container text-center flex-shrink-0">
                    <div class="date-number">&#x27F6;</div>
                  </div>
                </div>
              </div>
             
            );
          })} */}
          <div class="w100 mb-5">
            <table class="table table-bordered">
              <thead class="thead-light">
                <tr>
                  <th
                    style={{
                      width: "100px",
                    }}
                    scope="col"
                    class="text-center"
                  >
                    No
                  </th>
                  <th
                    style={{
                      width: "200px",
                    }}
                    scope="col"
                    class="text-center"
                  >
                    Tanggal
                  </th>
                  <th scope="col" class="px-4">
                    Nama
                  </th>
                  <th scope="col" class="px-4">
                    Lokasi
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {dataCurrent.map((d, i) => {
                  return (
                    <tr>
                      <td
                        class="text-center"
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        {itemOffset + i + 1}
                      </td>
                      <td class="text-center">
                        {" "}
                        <div class="date-number">
                          {moment(d.kegiatan_tgl_mulai).format("DD")}
                        </div>{" "}
                        <div class="date-month">
                          {moment(d.kegiatan_tgl_mulai).format("MMM-YYYY")}
                        </div>{" "}
                      </td>
                      <td
                        class="px-4"
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        {" "}
                        {d.kegiatan}
                      </td>
                      <td
                        class="px-4"
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        {" "}
                        {d.kegiatan_lokasi}
                      </td>
                      <td
                        class="px-4 text-center"
                        onClick={(e) => {
                          window.open(
                            server_detail_eproc +
                              "/paket-detail.html?id=" +
                              d.kegiatan_id
                          );
                        }}
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        {" "}
                        <a
                          style={{
                            color: "blue",
                            cursor: "pointer ",
                            textDecoration: "underline",
                            fontSize: "1rem",
                          }}
                          target="_blank"
                          onClick={(e) => {
                            window.open(
                              server_detail_eproc +
                                "/paket-detail.html?id=" +
                                d.kegiatan_id
                            );
                          }}
                        >
                          Lihat
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            className="pagination-class px-3"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            activeClassName="page-active"
          />
        </div>
      </>
    );
  };

  return (
    <>
      {/* <img class="w100" style={{ borderRadius: 20 }} src={backgroundImage}></img> */}
      <div className="pageTitle">
    Daftar E-Proc
      </div>
      <div className="articleContainer pageText">
        <div className="list image" style={{ textAlign: "justify" }}>
          <div class="flex" style={{ gap: 4 }}>
            <div
              class="tabMenu"
              id="/pengadaan_eproc"
              onClick={(e) => {
                setPage("/pengadaan_eproc");
              }}
            >
              <div class="tabName px-4 py-2"> Pengadaan</div>
              <div class="tabUnderline"></div>
            </div>

            <div
              class="tabMenu"
              id="/pengumuman_eproc"
              onClick={(e) => {
                setPage("/pengumuman_eproc");
              }}
            >
              <div class="tabName px-4 py-2"> Pengumuman</div>
              <div class="tabUnderline"></div>
            </div>
          </div>
        </div>
        {page == "/pengadaan_eproc" ? <Pengadaan /> :null}
        
        {page == "/pengumuman_eproc" ? <Pengumuman />:null }


        <ViewCount count={data.length > 0 ? data[0].views : 0} />
      </div>
    </>
  );
}
