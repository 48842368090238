import kbnLogoBot from "../assets/kbnLogoBot.png";
import locFooter from "../assets/locFooter.png";
import phoneFooter from "../assets/phoneFooter.png";
import faxFooter from "../assets/faxFooter.png";
import mailFooter from "../assets/mailFooter.png";
import ig from "../assets/ig.png";
import yt from "../assets/yt.png";
import fb from "../assets/fb.png";
import twitter from "../assets/twitter.png";
import linkedin from "../assets/linkedin.png";
import sub_prima from "../assets/sub_prima.png";
import sub_graha from "../assets/sub_graha.png";
import kcn from "../assets/kcn.png";
import sub_marunda from "../assets/sub_marunda.png";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { server } from "../config/server.js";
export default function Footer() {
  const navigate = useNavigate();

  const [data, setData] = useState({
    company_name: "PT. Kawasan Berikat Nusantara",
    company_phone1: "(021) 4482-0909",
    company_phone2: "(021) 4482-0042",
    company_address:
      "Jl. Raya Cakung Cilincing, Tanjung Priok, <br /> Jakarta Utara DKI Jakarta 14140 Indonesia",
    company_address_url:
      "https://www.google.com/maps/place/Kawasan+Berikat+Nusantara+PT+Persero/@-6.1482953,106.9312198,16z/data=!4m10!1m2!2m1!1spt+kbn!3m6!1s0x2e698a94273336d9:0x27d98f972c9ea18b!8m2!3d-6.1450765!4d106.9358472!15sCgZwdCBrYm6SARBjb3Jwb3JhdGVfb2ZmaWNl4AEA!16s%2Fg%2F1tdqdwxm?entry=ttu",
    company_email1: "sekper@kbn.co.id",
    company_email1_url: "mailto:sekper@kbn.co.id",
    company_email2: "sekperkbn@gmail.com",
    company_email2_url: "mailto:sekperkbn@gmail.com",
  });

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    fetch(`${server}/api/v1/home/companyInfo`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "tes");

        if (res.data && res.data.length > 0) {
          setData(res.data[0]);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div class="bottomMenuContainer mx-auto mt-5 pt-5 mw70">
        <div
          class="bottomMenuWrapper flex w100 justify-content-center "
          style={{}}
        >
          <div class="kbnLogoBottomDiv text-center">
            <div>
              <img
                class="kbnLogoBottom pointer mx-auto"
                style={{
                  width: 140,
                }}
                onClick={(e) => {
                  window.scrollTo(0, 0);
                  navigate("/");
                }}
                src={kbnLogoBot}
              />
            </div>
            <div class="flex mt-4 justify-content-center" style={{ gap: 25 }}>
              <img
                class="kbnLogoBottom pointer"
                style={{
                  maxWidth: 70,
                }}
                src={sub_prima}
                onClick={(e) => {
                  window.open("https://kbnprimalogistik.co.id/", "_blank");
                }}
              />
              <img
                class="kbnLogoBottom pointer"
                style={{
                  maxWidth: 70,
                }}
                src={sub_graha}
                onClick={(e) => {
                  window.open("https://webrs.rsumumpekerja-kbn.com/", "_blank");
                }}
              />
              <img
                class="kbnLogoBottom pointer"
                style={{
                  maxWidth: 70,
                }}
                src={sub_marunda}
              />
              <img
                class="kbnLogoBottom pointer"
                style={{
                  maxWidth: 70,
                  height: "fit-content",
                  alignSelf: "end",
                  paddingBottom: 5,
                }}
                src={kcn}
              />
            </div>
          </div>
          <div class="kbnDescBottom flex m-font">
            <div
              class="mx-auto m-block footerWrapper w100 h100  m-mx-auto mx-auto justify-content-center"
              style={{
                alignSelf: "center",
                color: "#005F3B",
                fontSize: 16,
                fontFamily: "Roboto",

                wordWrap: "break-word",

                whiteSpace: "nowrap",
              }}
            >
              <div
                class="pointer flex center-vertical m-mx-auto"
                style={{
                  gap: 15,
                }}
              >
                <div
                  style={{
                    fontWeight: "400",

                    wordWrap: "break-word",
                  }}
                  class="mb-1 m-font"
                >
                  <a>PT. Kawasan Berikat Nusantara</a>
                </div>
              </div>
              <div
                class="pointer flex center-vertical m-mx-auto"
                style={{
                  gap: 15,
                }}
              >
                <img style={{}} class="contactFooter" src={phoneFooter} />
                <div
                  style={{
                    fontWeight: "400",

                    wordWrap: "break-word",
                  }}
                  class="mb-1 m-font"
                >
                  <a> (021) 4482-0909</a>
                </div>
              </div>

              <div
                class="pointer flex center-vertical m-mx-auto m-none"
                style={{
                  gap: 15,
                }}
              >
                <img style={{}} class="contactFooter" src={faxFooter} />
                <div
                  style={{
                    fontWeight: "400",

                    wordWrap: "break-word",
                  }}
                  class="mb-1 m-font"
                >
                  <a> (021) 4482-0042</a>
                </div>
              </div>

              <div
                class="pointer flex center-vertical m-mx-auto"
                style={{
                  gap: 15,
                }}
                onClick={(e) => {
                  window.open(data.company_address_url, "_blank");
                }}
              >
                <img style={{}} class="contactFooter" src={locFooter} />
                <div
                  style={{
                    fontWeight: "400",

                    wordWrap: "break-word",
                  }}
                  class="mb-1 m-font"
                  dangerouslySetInnerHTML={{
                    __html: `<a>${data.company_address}</a>`,
                  }}
                ></div>
              </div>
              <div
                class="pointer flex center-vertical m-mx-auto"
                style={{
                  gap: 15,
                }}
              >
                <img style={{}} class="contactFooter" src={mailFooter} />
                <div
                  style={{
                    fontWeight: "400",

                    wordWrap: "break-word",
                  }}
                  class="mb-1 m-font"
                >
                  <span
                    onClick={(e) => {
                      window.location = data.company_email1_url;
                    }}
                    href="mailto:sekperkbn@gmail.com"
                  >
                    <a>{data.company_email1}</a>
                  </span>
                  /
                  <span
                    onClick={(e) => {
                      window.location = data.company_email2_url;
                    }}
                    href="mailto:sekperkbn@gmail.com"
                  >
                    <a>{data.company_email2}</a>
                  </span>
                </div>
              </div>
            </div>
            {/* <div class="kbnDesc" style={{ gap: 30 }}>
              <div
                style={{
                  width: "100%",
                  color: "#005F3B",
                  fontSize: 16,
                  fontFamily: "Roboto",

                  wordWrap: "break-word",
                  marginBottom: 30,
                  whiteSpace: "nowrap",
                }}
                class=" m11"
              >
                {" "}
                PT. Kawasan Berikat Nusantara (Persero)
                <p class="MsoNormal">
                  <span>
                    <div>Jl. Raya Cakung&nbsp;Cilincing,</div>

                    <div>Tg. Priok,&nbsp;Jakarta Utara 14140.</div>

                    <div> Telepon : (021) 4882-0040</div>

                    <div>Faks : (021) 4482-0068, 4482-0073</div>

                    <div>
                      {" "}
                      Email :{" "}
                      <a href="mailto:sekper@kbn.co.id">
                        &nbsp;sekper@kbn.co.id
                      </a>
                    </div>
                  </span>
                  <span></span>
                </p>
              </div>
          
            </div> */}
          </div>
          <div
            class="bottomMenuLink flex mobile-none"
            style={{ gap: 60, textTransform: "capitalize" }}
          >
            <div class="w100">
              {" "}
              <div
                onClick={(e) => {
                  navigate("/profil/sejarah-singkat");
                }}
                style={{
                  width: "100%",
                  color: "#005F3B",
                  fontSize: 16,

                  fontFamily: "Roboto",

                  wordWrap: "break-word",
                }}
                class="pointer nowrap mb-2 hoverBold m-font"
              >
                <a> profil perusahaan</a>{" "}
              </div>{" "}
              <div
                onClick={(e) => {
                  navigate("/profil/visi-misi");
                }}
                style={{
                  width: "100%",
                  color: "#005F3B",
                  fontSize: 16,

                  fontFamily: "Roboto",

                  wordWrap: "break-word",
                }}
                class="pointer nowrap mb-2 hoverBold m-font"
              >
                <a> Visi Misi</a>{" "}
              </div>{" "}
              <div
                onClick={(e) => {
                  navigate("/profil/manajemen");
                }}
                style={{
                  width: "100%",
                  color: "#005F3B",
                  fontSize: 16,

                  fontFamily: "Roboto",

                  wordWrap: "break-word",
                }}
                class="pointer nowrap mb-2 hoverBold m-font"
              >
                <a> Manajemen</a>{" "}
              </div>{" "}
              <div
                onClick={(e) => {
                  navigate("suara-pelanggan/whistleblower");
                }}
                style={{
                  width: "100%",
                  color: "#005F3B",
                  fontSize: 16,

                  fontFamily: "Roboto",

                  wordWrap: "break-word",
                }}
                class="pointer nowrap mb-2 hoverBold m-font"
              >
                <a> Whistleblower</a>{" "}
              </div>{" "}
            </div>
            <div class="w100">
              {" "}
              <div
                onClick={(e) => {
                  navigate("/media/berita");
                }}
                style={{
                  width: "100%",
                  color: "#005F3B",
                  fontSize: 16,

                  fontFamily: "Roboto",

                  wordWrap: "break-word",
                }}
                class="pointer nowrap mb-2 hoverBold m-font"
              >
                <a> Berita</a>{" "}
              </div>{" "}
              <div
                onClick={(e) => {
                  navigate("/katalog");
                }}
                style={{
                  width: "100%",
                  color: "#005F3B",
                  fontSize: 16,

                  fontFamily: "Roboto",

                  wordWrap: "break-word",
                }}
                class="pointer nowrap mb-2 hoverBold m-font"
              >
                <a> Katalog</a>{" "}
              </div>{" "}
              <div
                onClick={(e) => {
                  navigate("/media/pengumuman");
                }}
                style={{
                  width: "100%",
                  color: "#005F3B",
                  fontSize: 16,

                  fontFamily: "Roboto",

                  wordWrap: "break-word",
                }}
                class="pointer nowrap mb-2 hoverBold m-font"
              >
                <a> Pengumuman</a>{" "}
              </div>{" "}
              <div
                onClick={(e) => {
                  navigate("suara-pelanggan/ppid");
                }}
                style={{
                  width: "100%",
                  color: "#005F3B",
                  fontSize: 16,

                  fontFamily: "Roboto",

                  wordWrap: "break-word",
                }}
                class="pointer nowrap mb-2 hoverBold m-font"
              >
                <a> PPID</a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footerContainer w100 ">
        <div
          class="mx-auto m-w70 m-block footerWrapper text-center w100 h100 flex center-vertical m-mx-auto mx-auto justify-content-between px-5"
          style={{
            alignSelf: "center",
          }}
        >
          <div class="flex sosmedFooter" style={{ gap: 20 }}>
            <div
              class="center-vertical pointer"
              onClick={(e) => {
                window.open("https://www.youtube.com/@beritakbn", "_blank");
              }}
            >
              <img class="sosmedimg" src={yt}></img>
            </div>
            <div
              class="center-vertical pointer"
              onClick={(e) => {
                window.open("https://twitter.com/KBN_ID", "_blank");
              }}
            >
              <img class="sosmedimg" src={twitter}></img>
            </div>
            <div
              class="center-vertical pointer"
              onClick={(e) => {
                window.open("https://www.instagram.com/kbn_id/", "_blank");
              }}
            >
              <img class="sosmedimg" src={ig}></img>
            </div>
            <div
              class="center-vertical pointer"
              onClick={(e) => {
                window.open(
                  "https://m.facebook.com/profile.php/?id=100064348831286",
                  "_blank"
                );
              }}
            >
              <img class="sosmedimg" src={fb}></img>
            </div>{" "}
            <div
              class="center-vertical pointer"
              onClick={(e) => {
                window.open(
                  "https://www.linkedin.com/company/pt-kawasan-berikat-nusantara",
                  "_blank"
                );
              }}
            >
              <img class="sosmedimg" src={linkedin}></img>
            </div>
          </div>

          <div
            class="pointer flex center-vertical m-mx-auto copyright"
            style={{}}
          >
            <div
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontSize: 12,

                fontWeight: "400",

                wordWrap: "break-word",
              }}
            >
              <a>
                {" "}
                Copyright © 2024 - PT. Kawasan Berikat Nusantara All rights
                reserved.
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
