import React, { useEffect, useState } from "react";
import { server } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";
import logistikMain from "../../assets/logistikMain.png";

export default function InformasiPemegangSaham() {
  const [data, setData] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  useEffect(() => {
    getData();
    
  getDataContent();
    window.scrollTo(0, 0);
  }, []);
  const getDataContent = async () => {
    try {
      const response = await fetch(`${server}/api/v1/content/list/56`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      setData(result.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    try {
      const response = await fetch(`${server}/api/v1/home/downloadFile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result.status) {
        console.log(result);
        const download_data = result.data;
        let groupedData = {};

        for (let i = 0; i < download_data.length; i++) {
          const element = download_data[i];
          const parts = element.description.split("_");
          const cat = parts[0];
          const fileName = parts[1];
          // console.log(fileName,'kuc');

          element.name = fileName;
          if (groupedData[`${cat}`] && fileName !== "") {
            groupedData[`${cat}`].push(element);
          } else if (fileName !== "") {
            groupedData[`${cat}`] = [element];
          }

          console.log(parts);
        }

        console.log(groupedData);

        setDataFile(groupedData);
      } else {
        throw result;
      }
    } catch (err) {
      console.log(err);
    }
  };
  // const backgroundImage = data.length > 0 ? data[0].content_header_link.replace(/ /g, "%20") : null;
  const contentHTML =
    data.length > 0 && data[0].content_html
      ? data[0].content_html
      : "<p>No Content Available";

  return (
    <>
      {/* <img class="w100" style={{ borderRadius: 20 }} src={backgroundImage}></img> */}
      <div className="pageTitle">{data ? "Daftar Informasi Publik" : "Loading..."}</div>
      <div className="articleContainer ">
        <div className="list image" style={{ textAlign: "justify" }}>
          {/* <div dangerouslySetInnerHTML={{ __html: `<a>${contentHTML}</a>` }}></div> */}
          <div>
            {/* <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Accordion Item #1
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <strong>This is the first item's accordion body.</strong> It
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Accordion Item #2
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body"></div>
                </div>
              </div>
            </div> */}
            <div class="accordion" id="accordionExample">
              {Object.entries(dataFile).map(([key, items], i) => (
                <div key={key}>
                  {/* <h2 class="mb-3">{key}</h2> */}

                  <div class="accordion-item">
                    <h2 class="accordion-header" id={`heading${i}`}>
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#download${i}`}
                        aria-expanded="true"
                        aria-controls={`download${i}`}
                      >
                        {key}
                      </button>
                    </h2>
                    <div
                      id={`download${i}`}
                      class="accordion-collapse collapse show"
                      aria-labelledby={`heading${i}`}
                      // data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        {items.map((item, index) => (
                          <a href={item.download_public_link} target="_blank">
                            {" "}
                            <div
                              class="mb-2 px-4 py-2 "
                              style={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: 12,
                                transition: "0.2s",
                                cursor: "pointer",
                              }}
                            >
                              <div class="flex justify-content-between">
                                <div> {item.name}</div>
                                <div>
                                  <a
                                    style={{
                                      color: "blue",
                                      cursor: "pointer ",
                                      textDecoration: "underline",
                                    }}
                                    target="_blank"
                                  >
                                    Lihat
                                  </a>
                                </div>
                              </div>
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>

                  <ul></ul>
                </div>
              ))}
            </div>
          </div>
          <ol>
            {/* {data.map((d, i) => {
              return (
                <a
                  href={d.download_public_link}
                  class="hoverNoColor"
                  target="_blank"
                >
                  <li
                    class="mb-2 px-4 py-2 hoverDark"
                    style={{
                      backgroundColor: "#f5f5f5",
                      borderRadius: 12,
                      transition: "0.2s",
                      cursor: "pointer",
                    }}
                  >
                    <div class="flex justify-content-between">
                      <div> {d.description}</div>
                      <div>
                        <a
                          style={{
                            color: "blue",
                            cursor: "pointer ",
                            textDecoration: "underline",
                          }}
                          target="_blank"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                    <div>{d.penyedia}</div>
                    <div>{d.nilai_pekerjaan}</div>
                  </li>{" "}
                </a>
              );
            })} */}
          </ol>
        </div>
        <ViewCount count={data.length > 0 ? data[0].views : 0} />
      </div>
    </>
  );
}
